.demo-logo-vertical {
  /* height: 32px; */
  margin: 10px;
  /* background: rgba(255,255,255,.2); */
  border-radius: 6px;
  justify-content: center;
  align-content: center;
  display: flex;
}

.demo-logo-vertical img{
  height: 37px;
}